<template>
  <!-- login -->
  <div v-if="conditions.screen==screens.login">
    <div class="LoginPage-container">
      <div class="LoginPage-leftSide">
        <div class="voipBusiness-whiteLogo-container">
          <app-icon icon="voipBusiness-latestLogo-icon" class="voipBusiness-whiteLogo"/>
        </div>
        <img :src="sideBarImage"/>
      </div>
      <div class="LoginPage-rightSide">
        <div class="LoginPage-rightSide-inner">
          <b-form @submit.prevent="login()">
            <b-alert :show="!!api.login.error_message" variant="danger">{{ api.login.error_message }}</b-alert>
            <div class="mainHeading mb-40px mt-40px">Sign in</div>
            <div class="mainInput-container with-error mb-32px">
              <label>Email or Username</label>
              <div :class="`mainInput ${api.login.send?'disabled':''}`">
                <b-form-input 
                  placeholder="Please type your email or username" 
                  :disabled="api.login.send" 
                  v-model="forms.login.username" 
                  type="text"
                />
              </div>
              <template v-if="(forms.login.submitted && $v.forms.login.username.$invalid) || api.login.validation_errors.username">
                <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                <div class="errorTemplate-v2">
                  <p class=" animated bounceIntop">
                    <span v-if="!$v.forms.login.username.required">* Email or Username is required</span>
                    <template v-else-if="api.login.validation_errors.username">
                      <span v-for="(em, i) in api.login.validation_errors.username" :key="i">* {{ em }}</span>
                    </template>
                  </p>
                </div>
              </template>
            </div>
            <div class="mainInput-container with-error mb-10px">
              <label>Password</label>
              <div :class="`mainInput password ${api.login.send?'disabled':''}`">
                <b-form-input 
                  placeholder="Please type your password"
                  :disabled="api.login.send" 
                  :type="forms.login.password_show?'text':'password'" 
                  v-model="forms.login.password"
                />
                <span class="pass-icon">
                  <b-icon 
                    class="dialer-showpassword-icon cursor_pointer" 
                    :icon="forms.login.password_show ? 'eye-slash-fill' : 'eye-fill'" 
                    @click="api.login.send ? '' : forms.login.password_show=!forms.login.password_show" 
                  />
                </span>
              </div>
              <template v-if="(forms.login.submitted && $v.forms.login.password.$invalid) || api.login.validation_errors.password">
                <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                <div class="errorTemplate-v2">
                  <p class=" animated bounceIntop">
                    <span v-if="!$v.forms.login.password.required">* Password is required</span>
                    <template v-else-if="api.login.validation_errors.password">
                      <span v-for="(em, i) in api.login.validation_errors.password" :key="i">* {{ em }}</span>
                    </template>
                  </p>
                </div>
              </template>
            </div>
            <a class="link-black d-block mb-40px" @click="$router.push({name:routesNames.forget_password})">Forgot password?</a>
            <div v-if="GET_IS_RECAPTCHA_ENABLED" class="h-78px mb-24px mt-16px position-relative">
              <app-recaptcha 
                @load="forms.login.recaptcha=false"
                @verify="forms.login.recaptcha=true"
                @expire="forms.login.recaptcha=false"
                @fail="forms.login.recaptcha=false"
              />
              <template v-if="(forms.login.submitted && $v.forms.login.recaptcha.$invalid)">
                <div class="errorTemplate-v2" style="width:270px">
                  <p class=" animated bounceIntop">
                    <span v-if="!$v.forms.login.recaptcha.required">* Recaptcha required</span>
                  </p>
                </div>
              </template>
            </div>
            <!-- buttons -->
            <b-button :disabled="api.login.send" class="fullBlackBTN mt-16px mb-24px" type="submit" variant="primary">
              <app-spinner v-if="api.login.send" /> 
              <template v-else>
                Log in
              </template>
            </b-button>
            <div class="OR-seperater">
              <span>or</span>
            </div>
            <b-button :disabled="api.login.send" class="BlackOutlineBTN mt-24px" @click="$router.push({name: routesNames.signup})" variant="primary">
              Sign up 
            </b-button>
          </b-form>
        </div>
        <!-- <div class="bottomSection"> 
          Here for participating in a Meeting?
          <a @click="$router.push({name: 'conference'})" class="mx-2 font-weight-bold font-size-14 color-primary">Join Meeting</a>
        </div> -->
      </div>
    </div>
  </div>
  <!-- confirm_device -->
  <div v-else-if="conditions.screen==screens.confirm_device" class="dialer-login-form confirm_devices">
    <div class="signUpPage-container">
      <SipupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner">
          <b-form @submit.prevent="''">
            <b-alert :show="api.confirm_device.error_message?true:false" variant="danger">{{ api.confirm_device.error_message }}</b-alert>
            <div class="w-100 d-flex justify-content-center mt-40px">
              <app-icon height="179.85px" width="214.99px" icon="trustedDevicesMain-icon" />
            </div>
            <div class="mainText mt-40px">
              <b>This device is not on your trusted devices. Do you want to add it to your trusted Devices</b>
            </div>
            <b-button 
              :disabled="api.confirm_device.send" 
              @click="addConfirtmDevice('yes')" 
              class="fullBlackBTN mt-40px" 
              type="button" 
              variant="primary"
            >
              Yes
            </b-button>
            <b-button 
              :disabled="api.confirm_device.send" 
              @click="addConfirtmDevice('no')" 
              class="BlackOutlineBTN mt-20px" 
              type="button" 
              variant="primary"
            >
              No
            </b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <!-- pin_authorized -->
  <div v-else-if="conditions.screen==screens.pin_authorized" class="dialer-login-form ">
    <div class="signUpPage-container">
      <SipupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner">
          <b-form @submit.prevent="pinAuthorization()">
            <b-alert :show="!!api.pin_auth.error_message" variant="danger">{{ api.pin_auth.error_message }}</b-alert>
            <div class="mainHeading-smallerVersion mt-40px">Enter PIN code</div>
            <div class="signupLoginV2-sub-heading text-center color-primary mt-32px">
              We have just sent you an email to {{forms.login.username}} with a security PIN code. Please enter the PIN code below. 
            </div>
            <div class="latestPin-container bigger mt-32px with-error">
              <div class="latestPin-inner">
                <app-pin-code class="input" :numberslength="6" @change="forms.pin_auth.pin=$event;" @complete="forms.pin_auth.pin=$event;" />
              </div>
              <template v-if="(forms.pin_auth.submitted && $v.forms.pin_auth.pin.$invalid) || api.pin_auth.validation_errors.pin">
                <div class="errorTemplate-v2">
                  <p class=" animated bounceIntop">
                    <span v-if="!$v.forms.pin_auth.pin.required">* PIN is required</span>
                    <span v-else-if="!$v.forms.pin_auth.pin.valid">* PIN is invalid</span>
                    <template v-else-if="api.pin_auth.validation_errors.pin">
                      <span v-for="(em, i) in api.pin_auth.validation_errors.pin" :key="i">* {{ em }}</span>
                    </template>
                  </p>
                </div>
              </template>
            </div>
            <!-- buttons -->
            <b-button  class="fullBlackBTN mt-32px" type="submit" variant="primary">
              <app-spinner v-if="api.pin_auth.send" />
              <template v-else>Log in</template>
            </b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <!-- organizations -->
  <div v-else-if="conditions.screen==screens.organizations" class="dialer-login-form">
    <b-form @submit.prevent="''">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner">
            <b-alert :show="!!api.organization_login.error_message" variant="danger">{{ api.organization_login.error_message }}</b-alert>
            <div v-if="api.organization_login.send" class="d-flex justify-content-center align-items-center" >
              <app-spinner class="big" />
            </div>
            <div class="mainHeading-smallerVersion mb-16px">Select an organization</div>
            <ul class="organizationList-container">
              <li 
                v-for="organization in response.organizations" 
                :key="organization.id" 
                :disabled="api.organization_login.send" 
                @click="organizationlogin(organization)"
              >
                <a class="d-flex align-items-center justify-content-between ">
                  <span class="py-1 d-flex align-items-left">
                    <b>{{ `${organization.label}` }}</b>
                  </span>
                  <span class="extention-box">{{`${organization.uid}`}}</span>
                </a>
              </li>
              <!-- <li 
                v-for="n in 50" 
                :key="n.id" 
              >
                <a class="d-flex align-items-center justify-content-between ">
                  <span class="py-1 d-flex align-items-left">
                    <b>organization.label</b>
                  </span>
                  <span class="extention-box">uid</span>
                </a>
              </li> -->
            </ul>
          </div>
          <div class="bottomSection">
            Back to
            <a @click="conditions.screen=screens.login" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
          </div>
        </div>
      </div>
    </b-form>
  </div>
  <!-- extension occupied -->
  <div v-else-if="conditions.screen==screens.extension_occupied" class="dialer-login-form">
    <b-form @submit.prevent="''">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide extension_occupied">
          <div class="signUpPage-rightSide-inner">
            <b-alert :show="!!api.extension_signout.error_message" variant="danger">{{ api.extension_signout.error_message }}</b-alert>
            <div class="currentExtentions-container">
              <div class="mainHeading w-100 mb-0 mt-40px">Sign out of one of the devices</div>
              <div class="mainText text-center mb-16px w-100">Sign out of one of the devices below to continue</div>
              <div class="currentExtentions-box" v-for="extension in response.extensions" :key="extension.id">
                <app-icon :icon="extension | get_property('extra_data') | jsonParse | get_property('fp_data') | extensionDeviceInfo('icon')" />
                <div class="currentExtentions-heading">
                  {{ extension | get_property('extra_data') | jsonParse | get_property('fp_data') | extensionDeviceInfo('app-name') }}
                </div>
                <div class="currentExtentions-text">
                  Logged in from {{ extension | get_property('extra_data') | jsonParse | get_property('fp_data') | extensionDeviceInfo('ip') }}
                </div>
                <div class="currentExtentions-subText">
                  Logged in {{ extension | get_property('created_at') | filter_date_current({
                    output: {
                      calender: {
                        sameDay: '[Today] [at] hh:mm a',
                        nextDay: '[Tomorrow] [at] hh:mm a',
                        nextWeek: 'dddd [at] hh:mm a',
                        lastDay: '[Yesterday] [at] hh:mm a',
                        lastWeek: '[Last] dddd [at] hh:mm a',
                        sameElse: 'DD MMMM YYYY hh:mm a',
                      }
                    }
                  }) }}
                </div>
                <b-button 
                  :disabled="!!api.extension_signout.send" 
                  @click="extensionsignout(extension.id)" 
                  class="fullBlackBTN" 
                  type="button" 
                  variant="primary"
                >
                  <app-spinner v-if="api.extension_signout.send==extension.id" /> 
                  <template v-else>Sign out</template>
                </b-button>
              </div>

              <!-- <div class="currentExtentions-box" v-for="n in 10" :key="n.id">
                <app-icon icon="trustedDevice-desktop-icon" />
                <div class="currentExtentions-heading">
                  dfm,lgkl;
                </div>
                <div class="currentExtentions-text">
                  Logged in from 192.168.125.125
                </div>
                <div class="currentExtentions-subText">
                  Logged in  hh:mm a
                </div>
                <b-button 
                  :disabled="!!api.extension_signout.send" 
                  @click="extensionsignout(n.id)" 
                  class="fullBlackBTN" 
                  type="button" 
                  variant="primary"
                >
                  <app-spinner v-if="api.extension_signout.send==n.id" /> 
                  <template v-else>Sign out</template>
                </b-button>
              </div> -->
            </div>
          </div>
          <div class="bottomSection">
            Back to
            <a @click="conditions.screen=screens.login" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
          </div>
        </div>
      </div>
    </b-form>
  </div>
  <!-- blocked -->
  <div v-else-if="conditions.screen==screens.blocked" class="dialer-login-form notAllowed">
    <div class="notAllowed-inner">
      <div class="notAllowed-innerBox">
        <app-icon icon="newSignup-blockUser-icon" class="notAllowed-innerBox-icon" width="125.705px" height="124.001px" />
        <div class="notAllowed-innerBox-heading">User is Blocked</div>
        <div class="notAllowed-innerBox-text">
          Your account has been blocked. Please contact support for assistance.
        </div>
        <!-- <div class="notAllowed-innerBox-text">
          <span class="makeBold">Contact Number:</span> 123-456-7890
        </div> -->
        <button class="latestBlueButton mwd-265px mt-32px" @click="$modal.show('supportTicketModal')">
          <app-icon icon="newSignup-submitTicket-icon" height="38px" width="38px" />
          <span class="latestBlueButton-textPart">Submit a ticket</span>
        </button>
        <div class="notAllowed-innerBox-text">
          Back to
          <a @click="conditions.screen=screens.login" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
        </div>
      </div>
      <div class="notAllowed-rightSideBox">
        <app-icon icon="newSignup-supportTicketSideBar-icon" class="newSignup-supportTicketSideBar-icon" height="50px" width="50px" />
      </div>
      <!-- <div class="bottomSection">
        Back to
        <a @click="conditions.screen=screens.login" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
      </div> -->
    </div>
    <!-- <b-form @submit.prevent="''">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide extension_occupied">
          <div class="signUpPage-rightSide-inner">
            <span>you are blocked</span>
            <div>
              {{ response.blocked.message }}
            </div>
          </div>
          <div class="bottomSection">
            Back to
            <a @click="conditions.screen=screens.login" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
          </div>
        </div>
      </div>
    </b-form> -->
    <modal name="supportTicketModal" class="dialer_animation right_side_popup ticketDetailModal">
      <div v-if="false" class="w-100">
        <div v-for="n in 4" :key="n.id" :class="`latestShimmerDesign w-100 my-4`" style="height:80px;"></div>
      </div>
      <div v-else class="SupportTicketDetail-container">
        <div class="dialer-edit-header headerInMobile">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="dialer-edit-title newer mb-0">Ticket #123</h2>
            <b-icon @click="$modal.hide('supportTicketModal')" class="cursor_pointer" icon="x" font-scale="1.8" />
          </div>
          <div class="d-flex mt-2 w-100">
            <h2 class="dialer-edit-title newer mb-0">Subject:</h2>
            <span class="ticket-subject ms-2">
              ticket.subject
            </span>
          </div>
        </div>
        <div class="dialer-edit-header align-items-start">
          <div class="customWidth-parent">
            <div>
              <h2 class="dialer-edit-title newer mb-0">Ticket #123</h2>
              <div class="d-flex mt-2 customWidth">
                <h2 class="dialer-edit-title newer mb-0">Subject:</h2>
                <span class="ticket-subject ms-2">
                  ticket.subject
                </span>
              </div>
            </div>
          </div>
          <!-- <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" /> -->
          <!-- v-else -->
          <a class="newCloseButton" @click="$modal.hide('supportTicketModal')">
            <app-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <div class="ticketTopInfoContainer">
            <div class="ticketTopInfoContainer-row">
              <div class="ticketTopInfoContainer-column one">SUBMITTER</div>
              <div class="ticketTopInfoContainer-column two">myself</div>
            </div>
            <div class="ticketTopInfoContainer-row">
              <div class="ticketTopInfoContainer-column one">SUBMITTED</div>
              <div class="ticketTopInfoContainer-column two">25 days ago</div>
            </div>
            <div class="ticketTopInfoContainer-row">
              <div class="ticketTopInfoContainer-column one">DEPARTMENT</div>
              <div class="ticketTopInfoContainer-column two">Development</div>
            </div>
            <div class="ticketTopInfoContainer-row">
              <div class="ticketTopInfoContainer-column one">STATUS/PRIORITY</div>
              <div class="ticketTopInfoContainer-column two">Active</div>
            </div>
          </div>
          <div class="ticketConversation-container">
            <div v-if="false" class="h-100 d-flex d-flex justify-content-center align-items-center my-5">
              <div class="w-75 text-center mt-20px">
                <div class="latestGreyBox-heading-main">No message to see here</div>
                <div class="latestGreyBox-heading-description mt-10px">You can send one by writing a message below</div>
              </div>
            </div>
            <div v-else>
              <div :class="`ticketConversation-item sender`">
                <!-- ${message.user_id==userId? 'sender':'receiver'} -->
                <div class="ticketConversation-item-top">
                  <div class="ticketConversation-item-top-left">
                    Frank Fahad
                  </div>
                  <div class="ticketConversation-item-top-right">
                    <b-icon icon="clock" ></b-icon>
                    At 06:28 am , 11 Jul 2024
                  </div>
                </div>
                <div class="ticketConversation-item-bottom">
                  <p class="mb-0">
                    We apologise for the delayed response. Unfortunately, we do not offer SMS services in bulk. Our SMS service is available at a rate of 5 pence per message. Currently, we only provide call packages.

                    If there is anything else i can help you with please contact us
                  </p>
                </div>
              </div>
              <div :class="`ticketConversation-item`">
                <!-- ${message.user_id==userId? 'sender':'receiver'} -->
                <div class="ticketConversation-item-top">
                  <div class="ticketConversation-item-top-left">
                    Frank Fahad
                  </div>
                  <div class="ticketConversation-item-top-right">
                    <b-icon icon="clock" ></b-icon>
                    At 06:28 am , 11 Jul 2024
                  </div>
                </div>
                <div class="ticketConversation-item-bottom">
                  <p class="mb-0">
                    We apologise for the delayed response. Unfortunately, we do not offer SMS services in bulk. Our SMS service is available at a rate of 5 pence per message. Currently, we only provide call packages.

                    If there is anything else i can help you with please contact us
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="ticketConversation-actions">
            <template v-if="true">
              <!-- status.slug == 'active' -->
              <div class="ticketActions-heading">To update this ticket with more information please enter the details below:-</div>
              <div class="ticketActions-textArea">
                <textarea rows="7" placeholder="Write your message" class="w-100"></textarea>
                <!-- <p v-if="$v.forms.create_comment.$error" class="errorRed">
                  <span v-if="!$v.forms.create_comment.required">Message is required</span>
                </p> -->
              </div>
            </template>
            <div class="d-flex mt-16px">
              <button v-if="true" class="latestBlueButton mr-20px" >
                <app-icon icon="squared-updateTicket-icon" height="38px" width="38px" />
                <span class="latestBlueButton-textPart d-flex justify-content-center position-relative">
                  Update ticket
                </span>
              </button>
              <a class="newCloseButton" >
                <template v-if="true">
                  <app-icon icon="squared-openTicket-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Reopen ticket</span>
                </template>
                <template v-else>
                  <app-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close ticket</span>
                </template>
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { API } from "../service";
import { mapGetters, mapMutations } from 'vuex';
import SipupSlider from '../components/SipupSlider.vue'
import { SET_TOKEN, SET_USER } from '@/store/constants/mutations';
import { GET_DEVICE_INFO, GET_IS_RECAPTCHA_ENABLED } from '@/store/constants/getters';
import { routes_names } from '@/router/constants/routes-names';
const screens = {
  login: 'login',
  organizations: 'organizations',
  confirm_device: 'confirm_device',
  pin_authorized: 'pin_authorized',
  extension_occupied: 'extension_occupied',
  blocked: 'blocked',
}
const images = [
  require("@/assets/images/login/login-img1.png"),
  require("@/assets/images/login/login-img2.png"),
  require("@/assets/images/login/login-img3.png"),
  require("@/assets/images/login/login-img4.png"),
  require("@/assets/images/login/login-img5.png"),
  require("@/assets/images/login/login-img6.png"),
]
export default {
  name: 'Login',
  components: {
    SipupSlider
  },
  data(){
    return {
      forms: {
        login: this.$app.form({
          data: {
            username: '',
            password: '',
            recaptcha: false,
            password_show: false,
          }
        }),
        pin_auth: this.$app.form({
          data: {
            pin: '',
          }
        }),
      },
      response: {
        login: {},
        organizations: [],
        extensions: [],
        blocked: {},
      },
      conditions: {
        screen: screens.login,
      },
      api: {
        login: this.$app.api({
          error_message: true,
          validation_errors: true,
        }),
        pin_auth: this.$app.api({
          error_message: true,
          validation_errors: true,
        }),
        confirm_device: this.$app.api({
          error_message: true,
        }),
        organization_login: this.$app.api({
          error_message: true,
        }),
        extension_signout: this.$app.api({
          error_message: true,
        }),
      },
      selected: {
        organization: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_IS_RECAPTCHA_ENABLED,
      GET_DEVICE_INFO
    ]),
    screens(){ return screens },
    routesNames(){ return routes_names },
    sideBarImage(){ return images[Math.floor(Math.random() * images.length)] },
  },
  filters: {
    jsonParse(value){ return JSON.parse(value) },
    extensionDeviceInfo(data,value_type,current_device_id){
      const is_desktop = data?.platform=='desktop'
      const is_web = data?.platform=='web'
      const is_mobile = data?.platform=='mobile'
      const is_postman = data?.platform=='postman'
      const is_extension = data?.platform=='extension'
      const is_integration = data?.platform=='integration'
      const browser = data?.browser ?? ''
      const name = data?.name ?? ''
      const os = data?.os ?? ''
      const os_version = data?.os_version ?? ''
      const device_id = data?.device_id ?? ''
      if(value_type=='icon'){
        if(is_desktop) return 'trustedDevice-desktop-icon'
        else if(is_mobile) {
          if(os=='android') return 'trustedDevice-android-OSIcon'
          if(os=='ios') return 'trustedDevice-apple-OSIcon'
        } else if(is_web) {
          if(browser=='Chrome') return 'trustedDevice-chrome-icon'
          else if(browser=='Opera') return 'trustedDevice-opera-icon'
          else if(browser=='Edge') return 'trustedDevice-microsoft-icon'
          else if(browser=='Firefox') return 'trustedDevice-firefox-icon'
          else if(browser=='Safari') return 'trustedDevice-safari-icon'
        } else if (is_integration){
          if(name=='hubspot') return 'hubSpot-icon'
          else if(name=='zoho') return 'zoho-icon'
          else if(name=='salesforce') return 'salesForce-icon'
        } else if (is_postman) return 'trustedDevice-chrome-icon'
        else if(is_extension) return 'trustedDevice-chrome-icon'
      } else if (value_type=='osicon') {
        if(os=='android') return 'trustedDevice-androidPhone-icon'
        else if(os=='ios') return 'trustedDevice-IPhone-icon'
        else if(os=='Windows' && os_version=='11') return 'trustedDevice-window11-OSIcon'
        else if(os=='Windows') return 'trustedDevice-window10-OSIcon'
        else if(os=='Linux') return 'trustedDevice-linux-OSIcon'
        else if(os?.includes?.('MAC')) return 'trustedDevice-appleMac-OSIcon'
        else return 'trustedDevice-window10-OSIcon'
      } else if(value_type=='ip'){
        return data?.ip ?? ''
      } else if(value_type=='location'){
        return `${data?.city ?? ''} ${data?.country ?? ''}`
      } else if(value_type=='app-name'){
        if(name) return name
        else if(is_desktop) return 'Desktop app'
        else if(is_mobile) return 'Mobile app'
        else if(is_web) return 'Web app'
        else if(is_postman) return 'Postman app'
        else if(is_extension) return 'Browser extension app'
      } else if(value_type=='current-device'){
        return current_device_id==device_id
      }
      return ''
    },
  },
  watch: {
    "conditions.screen": {
      immediate: true,
      handler(screen,old_screen){
        this.$emit('screen',screen)
        if(old_screen==screens.organizations){
          this.api.organization_login.reset()
        }
      }
    },
  },
  validations: {
    forms: {
      login: {
        username: {
          required: required,
        },
        password: {
          required: required,
        },
        recaptcha: {
          required: function(value){ 
            return this.GET_IS_RECAPTCHA_ENABLED ? value : true 
          },
        },
      },
      pin_auth: {
        pin: {
          required: required,
          valid: function(value){ 
            return value.length==6 
          },
        },
      },
    },
  },
  methods: {
    ...mapMutations([
      SET_USER,
      SET_TOKEN
    ]),
    async login(){
      this.forms.login.submitted=true
      this.$v.forms.login.$touch()
      if(this.api.login.send || this.$v.forms.login.$invalid) return;
      try {
        this.api.login.send=true
        this.api.login.validation_errors={}
        this.api.login.error_message=''
        const { data } = await API.endpoints.auth.userServer({
          username: this.forms.login.username,
          password: this.forms.login.password,
          platform: 'web',
          type: 'login',
        },{
          default_message: 'There seems to be a issue try again later'
        })
        this.afterLogin(data)
      } catch (ex) {
        this.api.login.validation_errors = ex.own_errors || {}
        this.api.login.error_message = ex.own_message ?? ex.message
      } finally {
        this.api.login.send=false
        this.forms.login.submitted=false
      }
    },
    async organizationlogin(organization){
      if(this.api.organization_login.send) return;
      try {
        this.api.organization_login.send=true
        this.api.organization_login.error_message=''
        const { data } = await API.endpoints.auth.userServer({
          username: this.forms.login.username,
          password: this.forms.login.password,
          platform: 'web',
          type: 'org-login',
          id: organization.id,
          uid: organization.uid,
        })
        this.selected.organization=organization
        this.afterLogin(data)
      } catch (ex) {
        this.api.organization_login.error_message = ex.own_message
      } finally {
        this.api.organization_login.send=false
      }
    },
    async extensionsignout(id){
      if(this.api.extension_signout.send) return;
      try {
        this.api.extension_signout.send=id
        this.api.extension_signout.error_message=''
        const { data } = await API.endpoints.auth.userServer({
          username: this.forms.login.username,
          password: this.forms.login.password,
          platform: 'web',
          type: 'extn-signout',
          id: this.selected.organization?.id,
          uid: this.selected.organization?.uid,
          kick_extension: id,
        })
        this.afterLogin(data)
      } catch (ex) {
        this.api.extension_signout.error_message = ex.own_message
      } finally {
        this.api.extension_signout.send=''
      }
    },
    async addConfirtmDevice(device_confirm){
      if(this.api.confirm_device.send) return;
      try {
        this.api.confirm_device.send=true
        this.api.confirm_device.error_message=''
        const { data } = await API.endpoints.auth.userServer({
          username: this.forms.login.username,
          password: this.forms.login.password,
          platform: 'web',
          type: 'add-device',
          id: this.selected.organization?.id,
          uid: this.selected.organization?.uid,
          device_confirm: device_confirm,
        })
        this.afterLogin(data)
      } catch (ex) {
        this.api.confirm_device.error_message = ex.own_message
      } finally {
        this.api.confirm_device.send=false
      }
    },
    async pinAuthorization(){
      this.forms.pin_auth.submitted=true
      this.$v.forms.pin_auth.$touch()
      if(this.api.pin_auth.send || this.$v.forms.pin_auth.$invalid) return;
      try {
        this.api.pin_auth.send=true
        this.api.pin_auth.validation_errors={}
        this.api.pin_auth.error_message=''
        const { data } = await API.endpoints.auth.userServer({
          username: this.forms.login.username,
          password: this.forms.login.password,
          platform: 'web',
          type: '2FA',
          id: this.selected.organization?.id,
          uid: this.selected.organization?.uid,
          code: this.forms.pin_auth.pin,
        })
        this.afterLogin(data)
      } catch (ex) {
        this.api.pin_auth.validation_errors = ex.own_errors || {}
        this.api.pin_auth.error_message = ex.own_message
      } finally {
        this.api.pin_auth.send=false
        this.forms.pin_auth.submitted=false
      }
    },
    afterLogin(data={}){
      if (data.field === "blocked") {
        this.conditions.screen = screens.blocked;
        this.response.blocked=data || {}
      } else if (data.field === "organization") {
        this.response.organizations = data.data;
        this.conditions.screen = screens.organizations;
      } else if (data?.response?.device || data.device) {
        this.conditions.screen=screens.confirm_device
      } else if (data?.response?.TFA || data.TFA) {
        this.conditions.screen=screens.pin_authorized
      } else if (data?.response?.extension_occupied || data.extension_occupied){
        this.conditions.screen = screens.extension_occupied;
        this.response.extensions = data?.response?.extension_occupied_list || data.extension_occupied_list
      } else if (data.response) {
        this.response.login=data.response || {}
        if(data.response.vbToken){
          this.SET_USER({
            ...data.response,
            tab_id: this.GET_DEVICE_INFO.tab_id,
            username: this.forms.login.username,
          })
          this.SET_TOKEN(data.response.token)
          this.$router.push({
            name: routes_names.dashboard
          })
        }
      } else {
        this.response.login=data || {}
      }
    },
  },
}
</script>

<style lang="scss">
.login-button-v2{
  min-height:48px !important;
  .dialer-loader{
    transform:translateX(-50%);
    left:50%;
  }
}
</style>
